import React, { FC, useEffect, useState } from 'react';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { getDashboardSteps } from '../../../../common/components/Tutorial/tutorials';
import { Tooltip } from './Tooltip';
import {
  getSeenChatbot,
  setSeenChatbot,
} from '../../../../common/utils/helpers';
import { useRouter } from 'next/router';

export const DashboardTutorial: FC = () => {
  const [run, setRun] = useState(false);
  const steps = getDashboardSteps();
  const router = useRouter();

  useEffect(() => {
    const seenChatbot = getSeenChatbot();
    if (!seenChatbot) {
      setRun(true);
    }
  }, []);

  const handleClick = () => {
    setRun(false);
    setSeenChatbot();
    router.push('/ai-adviser');
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(data.status)) {
      setRun(false);
      setSeenChatbot();
    }
  };

  const handleClose = () => {
    setRun(false);
  };

  return (
    <Joyride
      run={run}
      steps={steps}
      scrollToFirstStep={true}
      showSkipButton={false}
      showProgress={false}
      callback={handleJoyrideCallback}
      tooltipComponent={(props) => (
        <Tooltip {...props} onClick={handleClick} onClose={handleClose} />
      )}
    />
  );
};
