import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Hidden, Typography } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { useUserData } from '../../hooks/useUserData';
import { minWidth } from '../../styles/helpers';
import { Icon } from '../Icon/Icon';
import { EmailSendIcon } from '../Icon/Icons/EmailSend.icon';
import { EmailVerification } from '../../../../__generated__/globalTypes';

type Props = {
  className?: string;
};

export const EmailVerificationInfo: FC<Props> = ({ className }) => {
  const { user } = useUserData();

  if (user.email && user.emailVerification === EmailVerification.PENDING) {
    return (
      <Wrapper className={className}>
        <Hidden smDown>
          <Icon Component={EmailSendIcon} />
        </Hidden>
        <StyledTypography>
          <Trans>
            Zkontroluj prosím tvoji e-mailovou schránku, poslali jsme ti
            potvrzovací e-mail, abychom měli jistotu, že je to tvůj e-mail.
            Stačí kliknout na odkaz v e-mailu.
          </Trans>
        </StyledTypography>
      </Wrapper>
    );
  }

  return null;
};
const Wrapper = styled.div`
  padding: 16px;
  border-radius: 16px;
  color: ${colors.white};
  background-color: ${colors.secondary};

  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 24px;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;
  //font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;

  ${minWidth.mobile} {
    font-size: 18px;
  }
`;
