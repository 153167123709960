import React, { FC } from 'react';
import { Grid, Typography, Link as MuiLink } from '@mui/material';
import { useQuery } from '@apollo/client';
import { getAllProjects } from '../../../../apollo/queries/__generated__/getAllProjects';
import { GET_ALL_PROJECTS } from '../../../../apollo/queries/getAllProjects.query';
import { Routes } from '../../../../common/enums/routes';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Link from 'next/link';
import { ProjectDetails } from './ProjectDetails';
import { Trans } from '@lingui/macro';
import { DashboardTutorial } from '../Tutorial';

export const MyProjectsDashboard: FC = () => {
  const { data, loading } = useQuery<getAllProjects>(GET_ALL_PROJECTS);

  return (
    <>
      <DashboardTutorial />
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h3" gutterBottom>
          <Trans>Moje projekty</Trans>
        </Typography>

        {data?.getAllProjects?.length > 1 && (
          <Link href={Routes.MyProjects}>
            <MuiLink variant="body2">
              <Trans>zobrazit vše</Trans>
            </MuiLink>
          </Link>
        )}
      </Grid>

      {loading && <div>loading</div>}

      {!loading && !data?.getAllProjects?.length && (
        <>
          <Typography gutterBottom>
            <Trans>
              Zatím nemáte žádné projekty. Začněte svou podnikatelskou cestu
              hned teď!
            </Trans>
          </Typography>

          <Link href={Routes.Project + Routes.New}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
            >
              <Trans>Přidat projekt</Trans>
            </Button>
          </Link>
        </>
      )}

      {!loading && data?.getAllProjects?.length > 0 && (
        <ProjectDetails project={data.getAllProjects[0]} />
      )}
    </>
  );
};
