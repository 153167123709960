import React, { FC } from 'react';
import { Close } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { TooltipRenderProps } from 'react-joyride';
import { AiAdviserIcon } from '../../../../common/components/Icon/Icons/AiAdviser.icon';
import {
  CloseButton,
  TooltipContainer,
  TooltipWrapper,
} from '../../../../common/styles/tooltipStyles';

interface TooltipProps extends TooltipRenderProps {
  onClick: () => void;
  onClose?: () => void;
}

export const Tooltip: FC<TooltipProps> = ({
  step,
  onClick,
  onClose,
  closeProps,
  tooltipProps,
}) => {
  return (
    <TooltipWrapper {...tooltipProps} {...closeProps}>
      <CloseButton onClick={onClose}>
        <Close color="primary" />
      </CloseButton>
      <TooltipContainer>
        <AiAdviserIcon width={45} height={42} />
        <Typography color="primary" variant="h3">
          {step.title}
        </Typography>
        <Typography color="black" align="center">
          {step.content}
        </Typography>
        <Button color="primary" onClick={onClick} variant="contained">
          Vyzkoušet
        </Button>
      </TooltipContainer>
    </TooltipWrapper>
  );
};
