import { IconButton, LinearProgress } from '@mui/material';
import { colors } from './colors';
import { minWidth } from './helpers';
import { spacings } from './spacings';
import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  background: ${colors.white};
  border-radius: 12px;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 100%;

  ${minWidth.mobile} {
    min-width: 300px;
  }
`;

export const StyledProgressBar = styled(LinearProgress)`
  height: 8px;
  border-radius: 4px;
  width: 100%;
  background-color: ${colors.white};
  margin-bottom: ${spacings.px16};
`;

export const CloseButton = styled(IconButton)`
  display: flex;
  align-self: end;
  margin-right: 5px;
  margin-top: 5px;
`;
