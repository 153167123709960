import { Step } from 'react-joyride';
import { t } from '@lingui/macro';

type StepsGetter = () => Step[];

export const getDiaryTutorialSteps: StepsGetter = () => [
  {
    title: t`Deník projektu`,
    content: t`Jak to vlastně funguje a co zde najdete? `,
    target: 'body',
    showSkipButton: true,
    placement: 'center',
  },
  {
    title: t`Fáze projektu`,
    content: t`Průvodce má čtyři fáze, kterými tě provází. Od vymýšlení nápadů až k rozjezdu firmy.`,
    target: '.diary-tutorial-step-1',
    placement: 'bottom',
  },
  {
    title: t`Doporučeno přímo tobě`,
    content: t`Podle toho, v jaké jsi fázi, ti zvýrazňujeme některé kapitoly. I když už jsi dál, doporučujeme nepřeskakovat předchozí fáze. Mohl/a jsi něco velmi důležitého přeskočit.`,
    target: '.diary-tutorial-step-2',
    placement: 'bottom',
  },
  {
    title: t`Sleduj tvůj pokrok`,
    content: t`Jak budeš Deníkem procházet, budeme ti ukazovat, kde se nacházíš a kolik ti ještě zbývá.`,
    target: '.diary-tutorial-step-3',
    placement: 'right',
  },
  {
    title: t`AI Poradce`,
    content: t`Poraď se o čemkoliv co se týká zakládání startupu s naším promakaným AI poradcem!`,
    target: '.ai-button',
    placement: 'right',
  },
];

export const getHowToPageSteps: StepsGetter = () => [
  {
    title: t`Kapitola`,
    content: t`Každá kapitola se skládá ze tří částí: video s podnikateli, následně špetka teorie a potom konkrétní nástroje a kroky, co pro splnění udělat.`,
    target: '.howTo-tutorial-step-1',
    placement: 'center',
    showSkipButton: true,
    disableScrolling: true,
  },

  {
    title: t`Poznámky`,
    content: t`Pokud chceš, piš si poznámky k jednotlivým tématům. Srovnání myšlenek i možnost exportu se může hodit pro potenciální investory i spoluzakladatele.`,
    target: '.howTo-tutorial-step-2',
    placement: 'bottom',
  },

  {
    title: t`Pokračování`,
    content: t`Jakmile budeš s kapitolou hotov, označ si ji jako dokončenou. Uvidíš tak hezky svůj posun kupředu.`,
    target: '.howTo-tutorial-step-3',
  },
];

export const getChatbotSteps: StepsGetter = () => [
  {
    title: t`Lorem Ipsum`,
    content: t`Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    target: '.chatbot-tutorial-step-1',
    placement: 'center',
    showSkipButton: true,
  },

  {
    title: t`Dolor sit amet`,
    target: '.chatbot-tutorial-step-2',
    content: t`Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident`,
    placement: 'bottom',
  },
  {
    title: t`Consectetur adipiscing elit`,
    content: t`sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    target: '.copilotKitInput',
    placement: 'bottom',
  },
];

export const getDashboardSteps: StepsGetter = () => [
  {
    title: t`AI Poradce`,
    content: t`Poraď se o čemkoliv co se týká zakládání startupu s naším promakaným AI poradcem!`,
    target: '.ai-button',
    placement: 'right',
    disableBeacon: true,
    spotlightPadding: 2,
    spotlightClicks: true,
  },
];
